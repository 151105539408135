import { Layout } from "antd";
import styled from "styled-components";

const { Sider } = Layout;

export const StyledSider = styled(Sider)`
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected {
    background-color: #5600ff;
    color: black;
  }
`;
