import { Table, message, Tag, Avatar, Empty } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getRegistrationRequests,
  SERVER_BASE_URL,
} from "../api/registrationDeskApis";
import { UserContext } from "../App";
import { StyledDiv } from "./styledComponents/styledDiv";
import avatar from "../assets/avatar.png";
import { LIMIT } from "../constants";
import Search from "antd/lib/input/Search";

export const RegistrationRequests = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [data, setData] = useState(null);
  const [playerCount, setPlayerCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const { registrationDesk } = useContext(UserContext);

  const getPlayerStatus = (status) => {
    const STATUSES = {
      0: "Pending",
      1: "On Hold",
      2: "Approved",
      3: "Verified",
    };

    const statusColors = {
      Verified: "blue",
      Pending: "gray",
      Approved: "green",
      "On Hold": "red",
    };

    return <Tag color={statusColors[STATUSES[status]]}>{STATUSES[status]}</Tag>;
  };

  async function loadRequests() {
    if (!registrationDesk) return;

    const offset = (currentPage - 1) * LIMIT;

    setLoading(true);
    getRegistrationRequests(LIMIT, offset, searchText)
      .then((res) => {
        setPlayerCount(res.data.requests.count);
        let rows = [];
        res.data.requests.rows.map((item) => {
          const player = {
            key: item.playerId,
            regno: item.registrationNumber,
            fullName: item.fullName,
            name: (
              <>
                <Avatar
                  src={
                    item.photo
                      ? SERVER_BASE_URL + `/get-player-image?key=${item.photo}`
                      : avatar
                  }
                />
                <label style={{ paddingLeft: "15px" }}>{item.fullName}</label>
              </>
            ),
            club: (item.club && item.club.name) || "-",
            email: item.email || "-",
            status: getPlayerStatus(item.status),
            createdAt: new Date(item.createdAt).toLocaleDateString(),
          };
          return rows.push(player);
        });
        setData(rows);
      })
      .catch((err) => {
        message.error("Could not connect with the server. Please try again.");
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const getIndividualPlayerDetails = (id) => {
    navigate(`/registration-requests/${id}`, { state: id });
  };

  const columns = [
    {
      title: "Reg. Number",
      dataIndex: "regno",
      width: "10%",
    },
    {
      title: "Full Name",
      dataIndex: "name",
      width: "20%",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "20%",
    },
    {
      title: "Club",
      dataIndex: "club",
      width: "20%",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "10%",
    },
  ];

  const emptyComponent = {
    emptyText: (
      <Empty
        description={
          <span>
            {searchText === ""
              ? "No pending requests."
              : "No results matching your search input."}
          </span>
        }
      />
    ),
  };

  useEffect(() => {
    loadRequests();
  }, [registrationDesk]);

  useEffect(() => {
    loadRequests();
  }, [currentPage]);
  return (
    <div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <StyledDiv>
          <Search
            placeholder="Search using Register Number, Name or Email"
            value={searchText}
            onSearch={loadRequests}
            onChange={(e) => setSearchText(e.target.value)}
            enterButton
          />
        </StyledDiv>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div>
            <h1
              style={{
                fontSize: "large",
                fontWeight: "bolder",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              PENDING REGISTRATION REQUESTS{" "}
              {data && playerCount && " (" + playerCount + ")"}
            </h1>
          </div>
          <Table
            columns={columns}
            locale={emptyComponent}
            loading={loading}
            dataSource={data}
            pagination={{
              current: currentPage,
              pageSize: LIMIT,
              showSizeChanger: false,
              total: playerCount,
              onChange: (value) => setCurrentPage(value),
            }}
            onRow={(record, index) => {
              return {
                onClick: (event) => {
                  getIndividualPlayerDetails(record.regno);
                },
              };
            }}
          />
        </div>
      </div>
    </div>
  );
};
