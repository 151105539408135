import "antd/dist/antd.min.css";
import { Login } from "./pages/login";
import { Home } from "./pages/home";
import { createContext, useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Layout } from "./components/layout";
import { RequireAuth } from "./components/RequireAuth";
import { PersistentLogin } from "./components/persistentLogin";
import {
  getCurrentDocumentsConfig,
  getUserDetails,
} from "./api/registrationDeskApis";
import { message } from "antd";
import AuthContext from "./context/AuthProvider";

export const UserContext = createContext();

function App() {
  const [registrationDesk, setRegistrationDesk] = useState(null);
  const { setAuth } = useContext(AuthContext);

  const navigate = useNavigate();

  const [documentsConfig, setDocumentsConfig] = useState(null);

  const loadUserDetails = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const res = await getUserDetails();
        setRegistrationDesk(res.data.registrationDesk);
      } catch {
        localStorage.removeItem("token");
        localStorage.removeItem("registrationDesk");
        setAuth({});
        navigate("/login", { replace: false });
        message.error("Something went wrong! Please try again.");
      }
    }
  };

  const loadDocumentsConfig = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const res = await getCurrentDocumentsConfig();

        setDocumentsConfig(res.data.documentsConfig);
      } catch (err) {
        setDocumentsConfig(null);
        localStorage.removeItem("token");
        message.error("Something went wrong! Please try again.");
        return navigate("/login", { replace: true });
      }
    }
  };

  useEffect(() => {
    loadUserDetails();
    loadDocumentsConfig();
  }, []);

  return (
    <UserContext.Provider
      value={{
        registrationDesk,
        setRegistrationDesk,
        documentsConfig,
        setDocumentsConfig,
        loadDocumentsConfig,
      }}
    >
      <Routes>
        <Route>
          <Route element={<Layout />}>
            <Route path="/login" element={<Login />} />
            <Route element={<PersistentLogin />}>
              <Route element={<RequireAuth />}>
                <Route path="/*" element={<Home />} />
              </Route>
            </Route>
            <Route
              path="*"
              element={<main className="app">404 Page not found!</main>}
            />
          </Route>
        </Route>
      </Routes>
    </UserContext.Provider>
  );
}

export default App;
