import { Table, message, Empty, Image } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllClubs, SERVER_BASE_URL } from "../api/registrationDeskApis";
import { UserContext } from "../App";
import { StyledDiv } from "./styledComponents/styledDiv";
import avatar from "../assets/avatar.png";
import Search from "antd/lib/input/Search";

export const Clubs = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [data, setData] = useState(null);
  const [clubsCount, setClubsCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const { registrationDesk } = useContext(UserContext);

  async function loadRequests() {
    if (!registrationDesk) return;

    const offset = (currentPage - 1) * 20;

    setLoading(true);
    getAllClubs(20, offset, searchText)
      .then((res) => {
        setClubsCount(res.data.clubs.count);
        let rows = [];
        res.data.clubs.rows.map((item) => {
          const player = {
            key: item.clubId,
            clubNo: item.clubId,
            name: (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <Image
                    style={{ width: "60px", height: "60px" }}
                    src={
                      item.logo
                        ? SERVER_BASE_URL + `/get-club-logo?key=${item.logo}`
                        : avatar
                    }
                  />
                </div>
                <label style={{ paddingLeft: "15px" }}>{item.name}</label>
              </div>
            ),
            code: item.code || "-",
            email: item.email || "-",
            phone: item.phone || "-",
          };
          return rows.push(player);
        });
        setData(rows);
        window.scroll(0, 0);
      })
      .catch((err) => {
        message.error("Could not connect with the server. Please try again.");
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const getIndividualClubDetails = (id) => {
    navigate(`/clubs/${id}`, { state: id });
  };

  const columns = [
    {
      title: "Club No.",
      dataIndex: "clubNo",
      width: "10%",
    },
    {
      title: "Club Name",
      dataIndex: "name",
      width: "30%",
    },
    {
      title: "Club Code",
      dataIndex: "code",
      width: "10%",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "25%",
    },
    {
      title: "Mobile Number",
      dataIndex: "phone",
      width: "15%",
    },
  ];

  const emptyComponent = {
    emptyText: (
      <Empty
        description={
          <span>
            {searchText === ""
              ? "No pending requests."
              : "No results matching your search input."}
          </span>
        }
      />
    ),
  };

  useEffect(() => {
    loadRequests();
  }, [registrationDesk]);

  useEffect(() => {
    loadRequests();
  }, [currentPage]);
  return (
    <div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <StyledDiv>
          <Search
            placeholder="Search using Register Number, Name or Email"
            value={searchText}
            onSearch={loadRequests}
            onChange={(e) => setSearchText(e.target.value)}
            enterButton
          />
        </StyledDiv>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div>
            <h1
              style={{
                fontSize: "large",
                fontWeight: "bolder",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              CLUBS
              {data && clubsCount && " (" + clubsCount + ")"}
            </h1>
          </div>
          <Table
            columns={columns}
            locale={emptyComponent}
            loading={loading}
            dataSource={data}
            pagination={{
              current: currentPage,
              pageSize: 20,
              showSizeChanger: false,
              total: clubsCount,
              onChange: (value) => setCurrentPage(value),
            }}
            onRow={(record, index) => {
              return {
                onClick: (event) => {
                  getIndividualClubDetails(record.clubNo);
                },
              };
            }}
          />
        </div>
      </div>
    </div>
  );
};
