import { Table, message, Tag, Avatar, Empty, Button, Radio } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  exportAllPlayers,
  getAllPlayers,
  SERVER_BASE_URL,
} from "../api/registrationDeskApis";
import { UserContext } from "../App";
import { StyledDiv } from "./styledComponents/styledDiv";
import avatar from "../assets/avatar.png";
import { LIMIT } from "../constants";
import Search from "antd/lib/input/Search";
import { saveAs } from "file-saver";
import { Workbook } from "exceljs";
import { DownloadOutlined } from "@ant-design/icons";

export const AllPlayers = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [exporting, setExporting] = useState(false);

  const [data, setData] = useState(null);
  const [playerCount, setPlayerCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [active, setActive] = useState("active");

  const { registrationDesk } = useContext(UserContext);

  const getPlayerStatus = (status) => {
    const STATUSES = {
      0: "Pending",
      1: "On Hold",
      2: "Approved",
      3: "Verified",
    };

    const statusColors = {
      Verified: "blue",
      Pending: "gray",
      Approved: "green",
      "On Hold": "red",
    };

    return <Tag color={statusColors[STATUSES[status]]}>{STATUSES[status]}</Tag>;
  };

  async function loadPlayers() {
    if (!registrationDesk) return;

    const offset = (currentPage - 1) * LIMIT;

    setLoading(true);
    getAllPlayers(LIMIT, offset, searchText, active)
      .then((res) => {
        setPlayerCount(res.data.players.count);
        const rows = [];

        res.data.players.rows.map((item) => {
          const player = {
            key: item.playerId,
            regno: item.registrationNumber,
            fullName: item.fullName,
            name: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src={
                    item.photo
                      ? SERVER_BASE_URL + `/get-player-image?key=${item.photo}`
                      : avatar
                  }
                />

                <label style={{ paddingLeft: "15px" }}>{item.fullName}</label>

                <Tag
                  style={{ marginLeft: "auto" }}
                  color={item.isActive ? "green" : "default"}
                >
                  {item.isActive ? "Active" : "Inactive"}
                </Tag>
              </div>
            ),
            club: (item.club && item.club.name) || "-",
            email: item.email || "-",
            status: getPlayerStatus(item.status),
            createdAt: new Date(item.createdAt).toLocaleDateString(),
          };
          return rows.push(player);
        });

        setData(rows);
        window.scroll(0, 0);
      })
      .catch((err) => {
        message.error("Could not connect with the server. Please try again.");
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function handleExport() {
    setExporting(true);
    try {
      const response = await exportAllPlayers();
      const players = response.data.players;

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("All Players");

      worksheet.columns = [
        { header: "Player ID", key: "playerId", width: 10 },
        { header: "Club ID", key: "clubId", width: 10 },
        { header: "Reg Number", key: "registrationNumber", width: 10 },
        { header: "Full Name", key: "fullName", width: 32 },
        { header: "Email", key: "email", width: 32 },
        { header: "Mobile Number", key: "phone", width: 32 },
        { header: "Date of Birth", key: "dateOfBirth", width: 15 },
        { header: "Blood Group", key: "bloodGroup", width: 15 },
        { header: "Home Address (India)", key: "homeAddress", width: 40 },
        { header: "Saudi Address", key: "homeAddress", width: 40 },
        { header: "Company Name", key: "companyName", width: 12 },
        { header: "Passport Number", key: "passportNumber", width: 12 },
        { header: "Iqama Number", key: "iqamaNumber", width: 12 },
        { header: "Is Active", key: "isActive", width: 12 },
      ];

      worksheet.addRows(players.rows);

      const buf = await workbook.xlsx.writeBuffer();

      saveAs(new Blob([buf]), "AllPlayers.xlsx");
    } catch (err) {
      message.error("Could not connect with the server. Please try again.");
      console.error(err);
    } finally {
      setExporting(false);
    }
  }

  const getIndividualPlayerDetails = (id) => {
    navigate(`/all-players/${id}`, { state: id });
  };

  const columns = [
    {
      title: "Reg. Number",
      dataIndex: "regno",
      width: "10%",
    },
    {
      title: "Full Name",
      dataIndex: "name",
      width: "25%",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "17%",
    },
    {
      title: "Club",
      dataIndex: "club",
      width: "18%",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "6%",
    },
  ];

  const emptyComponent = {
    emptyText: (
      <Empty
        description={
          <span>
            {searchText === ""
              ? "No pending requests."
              : "No results matching your search input."}
          </span>
        }
      />
    ),
  };

  useEffect(() => {
    loadPlayers();
  }, [registrationDesk, currentPage, active]);

  return (
    <div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <StyledDiv>
          <Search
            placeholder="Search using Register Number, Name or Email"
            value={searchText}
            onSearch={loadPlayers}
            onChange={(e) => setSearchText(e.target.value)}
            enterButton
          />
        </StyledDiv>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 10,
              alignItems: "center",
            }}
          >
            <h1
              style={{
                fontSize: "large",
                fontWeight: "bolder",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              ALL PLAYERS
              {data && playerCount && " (" + playerCount + ")"}
            </h1>

            <div style={{ marginLeft: "auto" }}>
              <Button
                type="primary"
                style={{ marginRight: "15px" }}
                id="exportButton"
                disabled={loading || exporting}
                onClick={handleExport}
              >
                <DownloadOutlined />
                {exporting ? "Exporting..." : "Export to Excel"}
              </Button>

              <Radio.Group
                onChange={(e) => setActive(e.target.value)}
                value={active}
                disabled={loading}
                buttonStyle="solid"
              >
                <Radio.Button value="active">Active</Radio.Button>
                <Radio.Button value="inactive">In-Active</Radio.Button>
              </Radio.Group>
            </div>
          </div>
          <Table
            columns={columns}
            locale={emptyComponent}
            loading={loading}
            dataSource={data}
            pagination={{
              current: currentPage,
              pageSize: LIMIT,
              showSizeChanger: false,
              total: playerCount,
              onChange: (value) => setCurrentPage(value),
            }}
            onRow={(record, index) => {
              return {
                onClick: (event) => {
                  getIndividualPlayerDetails(record.regno);
                },
              };
            }}
          />
        </div>
      </div>
    </div>
  );
};
