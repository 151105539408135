import { useContext, useState } from "react";
import { Button, Card, Form, Input, message, Spin } from "antd";
import { login } from "../api/registrationDeskApis";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../App";

export const LoginForm = () => {
  let navigate = useNavigate();

  const { setAuth } = useAuth();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { registrationDesk, setRegistrationDesk } = useContext(UserContext);

  const inputChange = (input, func) => {
    func(input);
  };

  const isValid = () => {
    if (email === "" || password === "") {
      return false;
    }

    if (!isEmail(email)) {
      return false;
    }

    return true;
  };

  const isEmail = (email) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onSubmit = () => {
    if (!isValid()) return;

    setLoading(true);
    login(email, password)
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          message.success("Logged In Successfully");
          const token = res.data.token;
          localStorage.setItem("token", token);
          localStorage.setItem(
            "registrationDesk",
            JSON.stringify(res.data.registrationDesk)
          );

          setRegistrationDesk(res.data.registrationDesk);
          setAuth({ token, registrationDesk });
          return navigate("/registration-requests", { replace: true });
        }
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          message.error("Invalid Credentials! Try Again.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card
      style={{
        width: "30%",
        display: "flex",
        justifyContent: "center",
        border: "2px solid #ebebeb",
      }}
    >
      <div style={{ textAlign: "center", paddingBottom: "20px" }}>
        <h1>Login | Registration Desk</h1>
      </div>

      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please enter your email!" }]}
        >
          <Input
            disabled={loading}
            title="Email"
            placeholder="Joe@email.com"
            value={email}
            onChange={(e) => {
              inputChange(e.target.value, setEmail);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please enter your password!" }]}
        >
          <Input.Password
            disabled={loading}
            placeholder="Enter your password"
            value={password}
            onChange={(e) => {
              inputChange(e.target.value, setPassword);
            }}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            disabled={loading || !isValid()}
            style={{ width: "100px" }}
            type="primary"
            onClick={() => {
              onSubmit();
            }}
          >
            {loading ? <Spin /> : "Login"}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
