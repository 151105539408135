import {
  EditFilled,
  FullscreenOutlined,
  LeftCircleOutlined,
  LikeOutlined,
  RightOutlined,
  StopOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { Card, message, Spin, Tag, Image, Button, Input, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  approvePlayerRequest,
  getPlayerRequestByRegisterNumber,
  resetPlayerRequest,
  SERVER_BASE_URL,
  withHoldPlayerRequest,
} from "../api/registrationDeskApis";
import { UserContext } from "../App";
import avatar from "../assets/avatar.png";
import { STATUSES } from "../constants";
import { StyledModal, StyledModalDanger } from "./styledComponents/styledModal";

export const IndividualRequest = () => {
  const { registrationNumber } = useParams();

  const [player, setPlayer] = useState();

  const [withHoldModalOpen, setWithHoldModalOpen] = useState(false);
  const [remark, setRemark] = useState("");

  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { registrationDesk } = useContext(UserContext);

  let navigate = useNavigate();

  async function loadPlayer() {
    getPlayerRequestByRegisterNumber(registrationNumber)
      .then((res) => {
        if (res.status === 200) {
          setPlayer(res.data.request);
        }
      })
      .catch((err) => {
        message.error("Could not connect with the server. Please try again.");
        console.error(err);
      });
  }

  async function approveRequest() {
    setLoading(true);
    approvePlayerRequest(player.playerId)
      .then(() => {
        message.success("Player approved successfully!");
        loadPlayer();
        setApproveModalOpen(false);
      })
      .catch((err) => {
        message.error("Something went wrong! Please try again.");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  async function withHoldRequest() {
    setLoading(true);
    withHoldPlayerRequest(player.playerId, remark)
      .then(() => {
        message.success("Player registration request has been with held!");
        loadPlayer();
        setWithHoldModalOpen(false);
      })
      .catch((err) => {
        message.error("Something went wrong! Please try again.");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  async function resetRequest() {
    setLoading(true);
    resetPlayerRequest(player.playerId)
      .then(() => {
        message.info("Request has has been reset!");
        loadPlayer();
        setResetModalOpen(false);
      })
      .catch((err) => {
        message.error("Something went wrong! Please try again.");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    loadPlayer();
  }, [registrationDesk]);

  const withHoldModal = (
    <StyledModalDanger
      title="Are you sure you want to withhold this request?"
      visible={withHoldModalOpen}
      closable={false}
      destroyOnClose={true}
      cancelText="Cancel"
      okText="Confirm"
      okButtonProps={{ disabled: loading }}
      onOk={withHoldRequest}
      onCancel={() => setWithHoldModalOpen(false)}
    >
      <div style={{ marginBottom: "10px" }}>
        Please enter a remark for withholding the request. (Optional)
      </div>
      <Input
        disabled={loading}
        placeholder="eg: Player passport document is not uploaded…"
        value={remark}
        onChange={(e) => setRemark(e.target.value)}
      />
    </StyledModalDanger>
  );

  const resetModal = (
    <StyledModalDanger
      title="Confirm Action"
      visible={resetModalOpen}
      closable={false}
      destroyOnClose={true}
      cancelText="Cancel"
      okText="Confirm"
      okButtonProps={{ disabled: loading }}
      onOk={resetRequest}
      onCancel={() => setResetModalOpen(false)}
    >
      Are you sure you want to reset this request?
    </StyledModalDanger>
  );

  const approveModal = (
    <StyledModal
      title="Confirm Action"
      visible={approveModalOpen}
      closable={false}
      destroyOnClose={true}
      cancelText="Cancel"
      okText="Confirm"
      onOk={approveRequest}
      okButtonProps={{ disabled: loading }}
      onCancel={() => setApproveModalOpen(false)}
    >
      Are you sure you want to approve this request?
    </StyledModal>
  );

  const getPlayerStatus = (status) => {
    const STATUSES = {
      0: "Pending",
      1: "On Hold",
      2: "Approved",
      3: "Verified",
    };

    const statusColors = {
      Verified: "blue",
      Pending: "gray",
      Approved: "green",
      "On Hold": "red",
    };

    return <Tag color={statusColors[STATUSES[status]]}>{STATUSES[status]}</Tag>;
  };

  const getSignedDocumentLink = (link) => {
    const token = localStorage.getItem("token");
    return `${SERVER_BASE_URL}/registration-desk/get-player-document?token=${token}&key=${link}`;
  };

  const getActionBar = () => {
    switch (player.status) {
      case STATUSES.approved:
        if (player.approvedBy === registrationDesk.role.toString()) {
          return (
            <Tooltip title="You have already taken your action on this request. Awaiting action from DIFA Registration Desk…">
              <Button type="primary" disabled>
                You have already operated on this request
              </Button>
            </Tooltip>
          );
        } else {
          return (
            <>
              <Button
                icon={<StopOutlined />}
                danger
                onClick={() => setWithHoldModalOpen(true)}
              >
                Withhold
              </Button>
              <Button
                icon={<LikeOutlined />}
                type="primary"
                disabled={loading}
                onClick={() => setApproveModalOpen(true)}
              >
                Approve
              </Button>
            </>
          );
        }
      case STATUSES.fullyApproved:
        return (
          <Button type="primary" disabled>
            Registration Verified
          </Button>
        );
      case STATUSES.onHold:
        return (
          <Button
            icon={<UndoOutlined />}
            type="outlined"
            danger
            disabled={loading}
            onClick={() => setResetModalOpen(true)}
          >
            Reset Request
          </Button>
        );
      default:
        return (
          <>
            <Button
              icon={<StopOutlined />}
              danger
              onClick={() => setWithHoldModalOpen(true)}
            >
              Withhold
            </Button>
            <Button
              icon={<LikeOutlined />}
              type="primary"
              disabled={loading}
              onClick={() => setApproveModalOpen(true)}
            >
              Approve
            </Button>
          </>
        );
    }
  };

  return player ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {withHoldModal}
      {resetModal}
      {approveModal}
      <div style={{ width: "70%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            gap: "1em",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              paddingBottom: "18px",
              flex: 0.01,
            }}
          >
            <Button
              icon={<LeftCircleOutlined />}
              style={{
                marginTop: "20px",
                border: "2px solid black",
                backgroundColor: "transparent",
                color: "black",
              }}
              onClick={() => {
                navigate("/registration-requests", { replace: true });
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              flex: 0.99,
              alignItems: "center",
            }}
          >
            <h1
              style={{
                fontSize: "large",
                fontWeight: "bolder",
                marginTop: "2%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              PLAYER DETAILS
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "5px",
              alignItems: "center",
            }}
          >
            {getActionBar()}
            <Button
              style={{ marginLeft: "auto" }}
              type="dashed"
              icon={<EditFilled />}
              onClick={() => {
                navigate(`/registration-requests/${registrationNumber}/edit`);
              }}
            >
              Edit
            </Button>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            gap: "2em",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Card
            title={
              <div style={{ width: "10%", height: "10%" }}>
                <Image
                  src={
                    player.photo
                      ? SERVER_BASE_URL +
                        `/get-player-image?key=${player.photo}`
                      : avatar
                  }
                  width={"100%"}
                />
              </div>
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <h2 style={{ paddingTop: "7px" }}>
                <b>{player.fullName}</b>
              </h2>
              <RightOutlined />
              <h2 style={{ paddingTop: "7px" }}>
                <b>{player.club?.name || "-"}</b>
              </h2>
            </div>
          </Card>
          <Card
            title="Player Details"
            style={{ width: "100%", marginBottom: "20px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1em",
                width: "100%",
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1em" }}
              >
                <Card title={"Full Name"} style={{ width: "50%" }}>
                  <div>
                    <b>{player.fullName}</b>
                  </div>
                </Card>
                <Card title={"Registration Number"} style={{ width: "50%" }}>
                  <div>
                    <b>{player.registrationNumber}</b>
                  </div>
                </Card>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1em" }}
              >
                <Card title={"Registering Club"} style={{ width: "50%" }}>
                  <div>
                    <b>{player.club && player.club.name}</b>
                  </div>
                </Card>
                <Card title={"Status"} style={{ width: "50%" }}>
                  <div>{getPlayerStatus(player.status)}</div>
                </Card>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1em" }}
              >
                <Card title={"Email"} style={{ width: "50%" }}>
                  <div>
                    <p>{player.email || "-"}</p>
                  </div>
                </Card>
                <Card title={"Mobile Number"} style={{ width: "50%" }}>
                  <div>
                    <p>{player.phone || "-"}</p>
                  </div>
                </Card>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1em" }}
              >
                <Card title={"Date of Birth"} style={{ width: "50%" }}>
                  <div>
                    <p>{player.dateOfBirth || "-"}</p>
                  </div>
                </Card>
                <Card title={"Blood Group"} style={{ width: "50%" }}>
                  <p>{player.bloodGroup || "-"}</p>
                </Card>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1em" }}
              >
                <Card title={"Home Address (India)"} style={{ width: "50%" }}>
                  <div>
                    <p>{player.homeAddress || "-"}</p>
                  </div>
                </Card>
                <Card title={"Saudi Address"} style={{ width: "50%" }}>
                  <div>
                    <p>{player.saudiAddress || "-"}</p>
                  </div>
                </Card>
              </div>
              <Card title="Company Name" style={{ width: "100%" }}>
                <div>
                  <p>{player.companyName || "-"}</p>
                </div>
              </Card>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1em" }}
              >
                <Card title={"Passport Number"} style={{ width: "50%" }}>
                  <div>
                    <p>{player.passportNumber || "-"}</p>
                  </div>
                </Card>
                <Card title={"Iqama Number"} style={{ width: "50%" }}>
                  <div>
                    <p>{player.iqamaNumber || "-"}</p>
                  </div>
                </Card>
              </div>
              <Card
                title={"Player Documents"}
                style={{
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1em",
                    justifyContent: "center",
                  }}
                >
                  <a
                    className="link"
                    href={
                      player.registrationForm
                        ? getSignedDocumentLink(player.registrationForm)
                        : null
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="button-link">
                      <p>
                        DIFA Registration Form
                        {!player.registrationForm && " - No File Uploaded"}
                      </p>
                      <FullscreenOutlined />
                    </button>
                  </a>
                  <a
                    className="link"
                    href={
                      player.affidavit
                        ? getSignedDocumentLink(player.affidavit)
                        : null
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="button-link">
                      <p>
                        Affidavit{!player.affidavit && " - No File Uploaded"}
                      </p>
                      <FullscreenOutlined />
                    </button>
                  </a>
                  <a
                    className="link"
                    href={
                      player.passport
                        ? getSignedDocumentLink(player.passport)
                        : null
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="button-link">
                      <p>Passport{!player.passport && " - No File Uploaded"}</p>
                      <FullscreenOutlined />
                    </button>
                  </a>
                  <a
                    className="link"
                    href={
                      player.iqama ? getSignedDocumentLink(player.iqama) : null
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="button-link">
                      <p>Iqama / Saudi Visa Copy{!player.iqama && " - No File Uploaded"}</p>
                      <FullscreenOutlined />
                    </button>
                  </a>
                </div>
              </Card>
            </div>
          </Card>
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
      }}
    >
      <Spin style={{ fontSize: "xx-large", color: "blue" }} />
    </div>
  );
};
